const experts = {
  apiBase:
    window.bdbconfig.apis.membersBase +
    'members?include=user,association,affiliate',
  apiUrl: null,
  resetButton: null,
  data: [],
  current_page: 1,
  resetStatus: false,
  offset: 4,
  pages: [],
  expertsList: [],
  fetchStatus: null,
  isLoaded: false,
  formData: {
    association: null,
    specialisation: null,
    specialisationFields: null,
    zip: null,
    q: null,
    is_specialist: 0,
    sort: [],
  },
  mappings: {
    q: 'q',
    association: 'filter[association.id]',
    specialisation: 'filter[specialisations.id]',
    specialisationFields: 'filter[specialisationFields.id]',
    zip: 'filter[zip_city]',
    is_specialist: 'filter[is_specialist]',
    sort: 'sort',
  },
  init() {
    this.apiUrl = this.apiBase;
    this.resetButton = document.querySelector(
      '.m-expertsearchcomponent__reset',
    );
    this.parseCurrentUrl();
    this.apiCall(this.apiUrl);

    this.$watch('formData.is_specialist', (value) => {
      console.log(value);
      if (value == 0) {
        this.formData.specialisationFields = null;
      }

      if (value == 1) {
        this.formData.specialisation = null;
      }

      this.formData.is_specialist = value;
    });

    this.$watch('data', (newData) => {
      this.current_page = newData.current_page;
    });

    window.addEventListener('dropdownInit', (e) => {
      this.formData[e.detail.name] = e.detail.value;
    });

    this.$watch('formData', (values) => {
      if (values.association && typeof values.association === 'object') {
        this.formData.association =
          values.association === '' ? null : values.association.value;
      }

      if (values.specialisation && typeof values.specialisation === 'object') {
        this.formData.specialisation =
          values.specialisation === '' ? null : values.specialisation.value;
      }

      if (
        values.specialisationFields &&
        typeof values.specialisationFields === 'object'
      ) {
        this.formData.specialisationFields =
          values.specialisationFields === ''
            ? null
            : values.specialisationFields.value;
      }

      this.setResetButtonState();
    });

    this.setResetButtonState();
  },
  sortBy(target) {
    let column = target.dataset.sortValue;

    if (this.formData.sort.indexOf(column) === -1) {
      this.formData.sort.push(column);
    } else {
      const columnIndex = this.formData.sort.indexOf(column);
      column = column.includes('-') ? column.replace('-', '') : `-${column}`;
      this.formData.sort[columnIndex] = column;
    }

    target.dataset.sortValue = column;
    this.filterByParams();
  },
  resetSort() {
    this.formData['sort'] = [];

    this.$dispatch('reset.window');
    this.search();
  },
  async apiCall() {
    let headers = new Headers();
    if (window.bdbconfig.apis.credentials) {
      headers.set(
        'Authorization',
        `Basic ${window.bdbconfig.apis.credentials}`,
      );
    }

    this.fetchStatus = 'loading';
    this.isLoaded = false;

    const response = await fetch(this.apiUrl, {headers: headers});
    this.data = await response.json();

    if (!response.ok) {
      const message = `An error occured: ${response.status}`;
      throw new Error(message);
    }

    this.expertsList = this.data.data;
    this.fetchStatus = 'idl';
    this.isLoaded = true;

    window.scrollTo({top: 0, behavior: 'smooth'});
  },
  changePage(number) {
    this.current_page = number;
    this.filterByParams();
  },
  changeToLastPage() {
    this.changePage(this.data.last_page);
  },

  resetForm() {
    for (let [key, value] of Object.entries(this.formData)) {
      if (key === 'is_specialist') {
        this.formData[key] = 0;
      } else if (key === 'sort') {
        this.formData['sort'] = [];
      } else {
        this.formData[key] = null;
      }
    }

    this.$dispatch('reset.window');
    this.search();
  },

  pagesNumber() {
    if (!this.data.to) {
      return [];
    }

    let from = this.data.current_page - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + this.offset * 2;
    if (to >= this.data.last_page) {
      to = this.data.last_page;
    }

    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }

    return pagesArray;
  },

  search() {
    this.current_page = 1;
    this.filterByParams();
  },

  filterByParams() {
    let paramsArray = [];
    const searchParams = new URLSearchParams();
    for (let [key, value] of Object.entries(this.formData)) {
      if (key === 'is_specialist' && value == 0) continue;

      if (this.mappings[key] && value && value != '') {
        paramsArray[this.mappings[key]] = value;
        searchParams.append(this.mappings[key], value);
      }
    }

    if (this.current_page > 1) {
      searchParams.append('page', this.current_page);
    }

    let queryParams = '';
    const stringifiedParams = searchParams.toString();
    if (stringifiedParams.length > 0) {
      queryParams = stringifiedParams;
    }

    this.apiUrl = `${this.apiBase}&${queryParams}`;
    this.updateUrlParams(queryParams);
    this.apiCall();
  },

  parseCurrentUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());
    const paramsReduced = this.reduceParams(params);

    if (paramsReduced.q) {
      this.formData.q = paramsReduced.q;
    }

    if (paramsReduced) {
      this.resetStatus = true;
    }

    if (paramsReduced) {
      this.apiUrl = `${this.apiUrl}&${new URLSearchParams(
        paramsReduced,
      ).toString()}`;
    }

    // the tab state  must be set to the correct state
    if (paramsReduced && paramsReduced['filter[is_specialist]']) {
      this.formData.is_specialist = 1;
    }
  },

  setResetButtonState() {
    let status = false;

    for (let [key, value] of Object.entries(this.formData)) {
      if (key !== 'sort' && value) {
        status = true;
      }
    }

    if (status) {
      this.resetButton.classList.add('is-active');
    } else {
      this.resetButton.classList.remove('is-active');
    }
  },

  updateUrlParams(params) {
    const historyParams =
      params != '' ? `?${params}` : window.location.href.split('?')[0];
    window.history.replaceState(null, null, historyParams);
  },

  reduceParams(params) {
    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v != null && v != ''),
    );
  },
};

if (window.breakpoints.current === 'xs') {
  experts.offset = 2;
}

window.experts = experts;
