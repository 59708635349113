import Accordion from '../_base/accordion';

class AccordionImage extends Accordion {
  constructor(heading, selector, section, arrow, content, images, showFirst) {
    super(heading, selector, content, arrow, showFirst);

    this.section = section;
    //this.arrow = arrow;
    //this.content = content;
    this.images = document.querySelector(section + ' ' + images);
    //this.showFirst = showFirst;

    this.hideImages();
  }

  // position images offscreen
  hideImages() {
    const images = this.images.querySelectorAll('svg');
    images.forEach((img, key) => {
      let imgWidth = img.getBoundingClientRect().width;

      img.setAttribute('data-width', -imgWidth);

      if (!this.showFirst || (this.showFirst && key > 0)) {
        img.style.right = '-' + imgWidth + 'px';
      } else {
        img.style.visibility = 'visible';
      }
    });
  }

  openItem(item, key) {
    console.log('open image');
    const arrow = item.querySelector(this.arrow);
    const heading = item.querySelector(this.content);
    const img = this.images.children[key];

    img.style.visibility = 'visible';
    img.style.right = '20px';
    arrow.classList.add('active');
    heading.classList.add('active');
  }

  closeItem(item, key) {
    const arrow = item.querySelector(this.arrow);
    const heading = item.querySelector(this.content);
    const img = this.images.children[key];

    img.style.right = img.getAttribute('data-width') + 'px';
    arrow.classList.remove('active');
    heading.classList.remove('active');

    window.setTimeout(function () {
      img.style.visibility = 'hidden';
    }, 500);
  }

  doOnResize() {
    const images = this.images.querySelectorAll('svg');
    images.forEach((img, key) => {
      let imgWidth = img.getBoundingClientRect().width;

      img.setAttribute('data-width', -imgWidth);

      if (parseInt(img.style.right) < 0) {
        img.style.right = '-' + imgWidth + 'px';
      }
    });
  }
}

const hasAccordionImage = document.querySelector('.m-accordionimage');

if (hasAccordionImage) {
  const accordion = document.querySelector('.m-accordionimage');

  const accordionImage = new AccordionImage(
    '.m-accordionimage__item',
    null,
    '.m-accordionimage',
    '.m-accordionimage__icon-arrow',
    '.m-accordionimage__content',
    '.m-accordionimage__image-swapper',
    true,
  );
  accordionImage.showOne();

  window.addEventListener('resize', function () {
    accordionImage.doOnResize();
  });
}
