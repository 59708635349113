import GLightbox from 'glightbox';

const lightbox = GLightbox({
  selector: '.glightboxTest'
});

window.addEventListener('load', () => {
  const glightbox = GLightbox({
    selector: '.glightbox'
  });
});
