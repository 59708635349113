window.toplink = {
  init: function() {

		const toplink = document.querySelector('.m-top__link')

		if (toplink) {
			toplink.addEventListener('click', function(e) {
				e.preventDefault();
	
				window.scrollTo({top: 0, behavior: 'smooth'});
			})
		}

  }
}

window.toplink.init()