window.affiliate = {
  is_active_class: 'is-active',

  init: function () {
    window.affiliate.events_button();
    window.affiliate.information_button();
    window.affiliate.contact_button();
    window.affiliate.affiliate_list_component();
    window.affiliate.affiliate_list();
  },

  events_button: function () {
    const affiliateEventsButton = document.querySelector(
      '.js-affiliate-events',
    );

    if (affiliateEventsButton) {
      affiliateEventsButton.addEventListener('click', function (e) {
        e.preventDefault();

        const affiliateEvents = document.querySelector('.m-event-teasers');

        affiliateEvents.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    }
  },

  information_button: function () {
    const affiliateInformationButton = document.querySelector(
      '.js-affiliate-information',
    );

    if (affiliateInformationButton) {
      affiliateInformationButton.addEventListener('click', function (e) {
        e.preventDefault();

        const affiliateInformation = document.querySelector('.m-posttiles');

        affiliateInformation.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    }
  },

  contact_button: function () {
    const affiliateContactButton = document.querySelector(
      '.js-affiliate-contact',
    );

    if (affiliateContactButton) {
      affiliateContactButton.addEventListener('click', function (e) {
        e.preventDefault();

        const affiliateContacts = document.querySelector('.m-contacts');

        affiliateContacts.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    }
  },

  affiliate_list_component: function () {
    const affiliateListButton = document.querySelector(
      //'.m-affiliate .m-affiliateslistcomponent__headline h2',
      '.js-affiliate-list',
    );

    if (affiliateListButton) {
      affiliateListButton.addEventListener('click', function (e) {
        e.preventDefault();

        if (affiliateListButton.classList.contains('is-open')) {
          affiliateListButton.classList.remove('is-open');
        } else {
          affiliateListButton.classList.add('is-open');
        }
        // add wrapper
        const affiliateItemsContainer = document.querySelector(
          '.m-affiliateslistcomponent__items-wrapper',
        );

        if (!affiliateItemsContainer) {
          const affiliateItems = document.querySelector(
            '.m-affiliateslistcomponent__items',
          );

          const affiliateItemsWrapper = document.createElement('div');
          affiliateItemsWrapper.classList.add(
            'm-affiliateslistcomponent__items-wrapper',
          );

          affiliateItems.parentNode.insertBefore(
            affiliateItemsWrapper,
            affiliateItems,
          );

          affiliateItemsWrapper.appendChild(affiliateItems);
        }

        // add close
        const affiliateItemsClose = document.querySelector(
          '.js-affiliate-close',
        );

        if (!affiliateItemsClose) {
          const affiliateItems = document.querySelector(
            '.m-affiliateslistcomponent__items',
          );

          const affiliateClose = document.createElement('div');
          affiliateClose.classList.add('m-affiliateslistcomponent__close');
          affiliateClose.classList.add('js-affiliate-close');

          affiliateItems.parentNode.insertBefore(
            affiliateClose,
            affiliateItems.nextSibling,
          );
        }

        window.affiliate.affiliate_list_close('.m-affiliateslistcomponent');

        const affiliateComponent = document.querySelector(
          '.m-affiliateslistcomponent',
        );
        affiliateComponent.classList.toggle(window.affiliate.is_active_class);
      });
    }
  },

  affiliate_list: function () {
    const affiliateListButtons = document.querySelectorAll(
      '.m-associationslist .m-associationslist__group',
    );

    if (affiliateListButtons) {
      for (const affiliateListButton of affiliateListButtons) {
        affiliateListButton.addEventListener('click', function (e) {
          e.preventDefault();

          const affiliateItem = affiliateListButton.closest(
            '.m-associationslist__item',
          );

          if (
            affiliateItem.classList.contains(window.affiliate.is_active_class)
          ) {
            affiliateItem.classList.remove(window.affiliate.is_active_class);
          } else {
            const affiliateItemActive = document.querySelector(
              '.m-associationslist .m-associationslist__item.' +
                window.affiliate.is_active_class,
            );

            if (affiliateItemActive) {
              affiliateItemActive.classList.remove(
                window.affiliate.is_active_class,
              );
            }

            affiliateItem.classList.add(window.affiliate.is_active_class);
          }

          window.affiliate.affiliate_list_close('.m-associationslist__item');
        });
      }
    }
  },

  affiliate_list_close: function (item) {
    const affiliateCloseButtons = document.querySelectorAll(
      '.js-affiliate-close',
    );

    const affiliateListButton = document.querySelector('.js-affiliate-list');

    if (affiliateCloseButtons) {
      for (const affiliateCloseButton of affiliateCloseButtons) {
        affiliateCloseButton.addEventListener('click', function (e) {
          e.preventDefault();

          const affiliateItem = affiliateCloseButton.closest(item);
          affiliateItem.classList.remove(window.affiliate.is_active_class);
          affiliateListButton.classList.remove('is-open');
        });
      }
    }
  },
};

document.addEventListener(
  'DOMContentLoaded',
  function () {
    window.affiliate.init();
  },
  false,
);
