window.map = {
  init: function() {
    const map = document.querySelector('.m-parallaxmap:not(.is-in-viewport)')

    if (map) {
      const observer = new window.IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          map.classList.add('is-in-viewport')

          return
        }
      }, {
        root: null
      })

      observer.observe(map)
    }
  }
}

window.map.init();
