window.imagetitle = {
  init: function() {
    const images = document.querySelectorAll('.m-imagetitle__content:not(.is-in-viewport) img')

    for (const image of images) {
      const observer = new window.IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          const container = image.closest('.m-imagetitle__content')
          container.classList.add('is-in-viewport')
  
          return
        }
      }, {
        root: null
      })
  
      observer.observe(image)
    }
  }
}

window.imagetitle.init();
