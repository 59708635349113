const toggleComponent = {
    toggle() {
        let form = document.getElementById('event-form');
        let state = form.getAttribute('data-toggle-state');

        let btn = document.getElementById('event-registration-button');

        state = state === "false" ? "true" : "false"
        form.setAttribute('data-toggle-state', state);

        if (state === "true") {
            btn.classList.add('active');
        }
        else {
            btn.classList.remove('active');
        }
    }
}

window.toggleComponent = toggleComponent;