window.header = {
  init: function() {
		if (window.pageYOffset > 150) {
			document.body.classList.add('js-has-scrolled');
		} else {
			document.body.classList.remove('js-has-scrolled');
		}
  }
}

window.addEventListener('load', function() {
	window.header.init();
});

window.addEventListener('scroll', function() {
	window.header.init();
}, false);
