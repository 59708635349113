window.logos = {
	init: function() {

		const logos = document.querySelectorAll('.m-logos');
		
		for (const logo of logos) {
			const logoitems = logo.querySelectorAll('.m-logos__logo-item');

			const rows = Math.ceil(logoitems.length/2);

			for (var i = 0; i < rows; i++) {
				const logoLinks = [];
				
				const linkItemOdd = logoitems[i*2];
				if(linkItemOdd) {
					const linkOdd = linkItemOdd.querySelector('.m-logos__link');

					if(linkOdd) {
						logoLinks.push(linkOdd);
					}
				}

				const linkItemEven = logoitems[(i*2)+1];
				if(linkItemEven) {
					const linkEven = linkItemEven.querySelector('.m-logos__link');

					if(linkEven) {
						logoLinks.push(linkEven);
					}
				}
				
				if(logoLinks.length > 1) {
					window.logos.equalheights(logoLinks);
				}
			}
		}
	},

	equalheights: function(logoLinks) {
		const minHeight = 0;
		let equalHeight = 0;

		for (const link of logoLinks) {
			equalHeight = (minHeight) ? minHeight : 0;

			if(link.getAttribute('style')) {
				link.removeAttribute('style');
			}

			const rect = link.getBoundingClientRect();
			const height = rect.height;

			if (height > equalHeight) {
				equalHeight = height;
			}
		}

		for (const link of logoLinks) {
			link.style.height = Math.ceil(equalHeight)+'px';
			// link.style.overflow = 'visible';
		}
	}
}

window.addEventListener('resize', function() {
	window.logos.init();
});

window.addEventListener('load', function() {
	window.logos.init();
});
