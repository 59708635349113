import {tns} from 'tiny-slider/src/tiny-slider'

window.newsslider = {
  load: function(gallery_base) {
    window.newsslider.slider(gallery_base)
  },

  slider: function(gallery_base) {
    var options = {
      "container": gallery_base,
      "slideBy": "page",
      "speed": 400,
      "autoWidth": false,
      "items": 1,
      "slideBy": 1,
      "mouseDrag": true,
      "lazyload": true,
      "swipeAngle": false,
      "navPosition": 'bottom',
      "controls": true,
      "responsive": {
        320: {
          items: 1,
        },
        768: {
          items: 2,
        },
        1024: {
          items: 3.3,
        }
      }
    };

    if ((window.breakpoints.current === 'xs') || (window.breakpoints.current === 'sm')) {
      
    }
    else {
      options.autoWidth = true;
      options.gutter = 28;
    }

    tns(options)
  }
}

const gallery_base = document.querySelectorAll('.m-newsslsider__list')
if (gallery_base) {
  for  (const base of gallery_base) {
    window.newsslider.load(base)
  }
}
