import Accordion from '../_base/accordion';

const hasAccordion = document.querySelector('.accordion');

if (hasAccordion) {
  const accordion = new Accordion(
    '.m-faq__item',
    null,
    '.m-faq__content',
    '.m-faq__icon-arrow',
    false,
  );

  accordion.showOne();
}
