import {tns} from 'tiny-slider/src/tiny-slider';

if (tns) {
  window.stageslider = {
    init: function () {
      const elements = document.querySelectorAll('.m-heroslider');
      if (elements) {
        for (const element of elements) {
          const items = element.querySelectorAll('.m-heroslider__item');
          if (items.length > 0) {
            window.stageslider.slider(element);
          }
        }
      }
    },

    slider: function (element) {
      const navcontainer = element.querySelector('.m-heroslider__navinner');
      const container = element.querySelector('.m-heroslider__items');

      tns({
        container: container,
        items: 1,
        center: true,
        slideBy: 'page',
        autoplay: true,
        controls: false,
        navPosition: 'top',
        nav: true,
        autoWidth: true,
        lazyload: true,
        navContainer: navcontainer,
        mouseDrag: true,
        autoplayButton: false,
        autoplayTimeout: 8000,
        autoplayHoverPause: true,
      });
    },
  };

  window.stageslider.init();
}
