export default class Accordion {
  constructor(heading, selector, content, arrow, showFirst) {
    this.heading = heading;
    this.selector = selector;
    this.content = content;
    this.arrow = arrow;
    this.showFirst = showFirst;
  }

  showOne() {
    const accordionHeading = document.querySelectorAll(this.heading); 

    accordionHeading.forEach((item, key) => {
      
      // prevent accordionItems from closing if an anchor was clicked:
      let anchors = item.querySelectorAll('a'); 
      anchors.forEach((aitem, key) => {
          aitem.addEventListener('click', (e) => {
            e.stopPropagation();
          });
          
          
      });

      let headline = item.querySelector('h3'); 

      headline.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (this.selector) {
          item = item.closest(this.selector);
        }

        accordionHeading.forEach((element, key) => {
          if (element !== item) {
            element.classList.contains('active')
              ? element.classList.remove('active')
              : null;
            this.closeItem(element, key);
          }
        });
        console.log("item is");
        console.dir(item);
        if (item.classList.contains('active')) {
          document.activeElement.blur();
          item.classList.remove('active');
          this.closeItem(item, key);
        } else {
          item.classList.add('active');
          this.openItem(item, key);
        }
        return false;
      });

      // open first Element if needed
      if (this.showFirst && key === 0) {
        item.classList.add('active');

        this.openItem(item, key);
      }
    });
  }

  showAll() {
    const accordionHeading = document.querySelectorAll(this.heading);

    accordionHeading.forEach((item, key) => {
      item.addEventListener('click', () => {

        if (item.classList.contains('active')) {
           item.classList.remove('active')
        } else {
           item.classList.add('active');

        }
      });
    });
  }

  openItem(item, key) {
   
    const arrow = item.querySelector(this.arrow);
    const heading = item.querySelector(this.content);

    arrow.classList.add('active');
    heading.classList.add('active');
    
    heading.style.maxHeight = heading.scrollHeight + "px";
  }

  closeItem(item, key) {
    const arrow = item.querySelector(this.arrow);
    const heading = item.querySelector(this.content);

    arrow.classList.remove('active');
    heading.classList.remove('active');
    heading.style.maxHeight = null;
  }
}
