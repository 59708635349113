import Choices from "choices.js";

window.choices = {
  init: function(base, list_item) {
    const elements = document.querySelectorAll('select:not(.nochoices)')

    if (elements) {
      // for (const element of elements) {
      elements.forEach(function(current_element, index) {

        const choices = new Choices(current_element, {
          searchEnabled: true,
          itemSelectText: '',
          allowHTML: false,
          removeItemButton: true,
          shouldSort: false
        });

      })
    }
  }
}

window.choices.init()
