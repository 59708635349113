const newsFilter = {
  init() {},

  removeFilter(name) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlPath = new URL(window.location.href);
    urlParams.delete(name);
    const url = urlParams.toString();
    let redirectUrl = url.length == 0 ? urlPath.pathname : '?' + url;

    // remove paging from url
    const pattern = /(page\/)\d\//gm;
    if (pattern.test(location.href)) {
      const pos = location.href.search(pattern);

      redirectUrl = location.href.substring(0, pos);
    }
    window.location.href = redirectUrl;
  },
};

window.newsFilter = newsFilter;
