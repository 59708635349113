window.searchoverlay = {
	is_active_class: 'is-active',

	init: function() {

		const searchbutton = document.querySelector('.m-headerbuttons__search')

		if (searchbutton) {
			searchbutton.addEventListener('click', function(e) {
				e.stopPropagation()
				e.preventDefault()

				const oSearch = document.querySelector('.l-header__search')
				oSearch.classList.toggle(window.searchoverlay.is_active_class)

				if (oSearch.classList.contains(window.searchoverlay.is_active_class)) {
					const searchInput = document.querySelector('.m-search__input')
					searchInput.focus()
				}

				return false
			})
		}


		const closebutton = document.querySelector('.m-search__close')

		if (closebutton) {
			closebutton.addEventListener('click', function(e) {
				const oSearch = document.querySelector('.l-header__search')
				oSearch.classList.remove(window.searchoverlay.is_active_class)
			})
		}

	}
}

window.searchoverlay.init()