window.navigation = {
  dropdowns: [],
  header: 0,

  init: function () {
    const mainMenu = document.getElementById('menu-main-menue');
    navigation.header = document.querySelector('.l-header');

    if (!mainMenu) return;

    const dropdowns = mainMenu.querySelectorAll('[data-menu-toggle]');

    if (dropdowns) {
      navigation.dropdowns = [...dropdowns];
      navigation.dropdowns.map((item) => {
        item.addEventListener('click', navigation.toggle);
      });
    }

    window.navigation.mobile();
  },

  toggle: (e) => {
    e.preventDefault();

    navigation.dropdowns.map((item) => {
      if (item !== e.target) {
        item.parentElement.classList.remove('is-toggled');
      }
    });

    const parentWrapper = e.target.parentElement;
    const dropdownWrapper = e.target.nextElementSibling;

    // height
    let adminBarHeight = 0;
    const adminBar = document.getElementById('wpadminbar');

    if (adminBar) {
      adminBarHeight = adminBar.offsetHeight;
    }

    dropdownWrapper.style.top =
      navigation.header.offsetHeight + adminBarHeight + 'px';

    // classes
    parentWrapper.classList.toggle('is-toggled');

    if (parentWrapper.classList.contains('is-toggled')) {
      document.body.classList.add('subnavigation-is-open');
      document.body.classList.add('navigation-overlay');
    } else {
      document.body.classList.remove('subnavigation-is-open');
      document.body.classList.remove('navigation-overlay');
    }

    // expertsearch
    window.navigation.expertsearch(dropdownWrapper);

    // close
    window.navigation.close(dropdownWrapper);
  },

  expertsearch: function (dropdownWrapper) {
    // clone expertsearch button (z-index)
    const expertSearchDiv = dropdownWrapper.querySelector(
      '.m-navigation__expertsearch',
    );

    if (!expertSearchDiv) {
      const listInner = dropdownWrapper.querySelector(
        '.m-navigation__list-inner',
      );

      const expertSearchWrapper = document.createElement('div');
      expertSearchWrapper.classList.add('m-navigation__expertsearch');
      listInner.appendChild(expertSearchWrapper);

      const expertSearch = document
        .querySelector('.m-expertsearch')
        .cloneNode(true);
      expertSearchWrapper.appendChild(expertSearch);
    }
  },

  close: function (dropdownWrapper) {
    // close submenu
    const close = dropdownWrapper.querySelector('.m-navigation__close');

    if (!close) {
      const listInner = dropdownWrapper.querySelector(
        '.m-navigation__list-inner',
      );

      const closeButton = document.createElement('div');
      closeButton.classList.add('m-navigation__close');

      listInner.appendChild(closeButton);

      closeButton.addEventListener('click', function (e) {
        e.preventDefault();

        const toggleItem = dropdownWrapper.closest('.is-toggled');
        toggleItem.classList.toggle('is-toggled');
        document.body.classList.remove('subnavigation-is-open');
        document.body.classList.remove('navigation-overlay');
      });
    }

    // close by click outside
    if (document.body.classList.contains('subnavigation-is-open')) {
      const main = document.querySelector('main');

      main.addEventListener('click', function (e) {
        if (document.body.classList.contains('subnavigation-is-open')) {
          e.preventDefault();

          const toggleItem = document.querySelector(
            '.m-navigation__item.is-toggled',
          );

          toggleItem.classList.remove('is-toggled');
          document.body.classList.remove('subnavigation-is-open');
          document.body.classList.remove('navigation-overlay');
        }
      });
    }
  },

  mobile: function () {
    // toggle menu button
    const menubutton = document.querySelector('.m-headerbuttons__menu');

    if (menubutton) {
      menubutton.addEventListener('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        document.body.classList.toggle('mobile-navigation-is-open');
        document.body.classList.toggle('navigation-overlay');
        menubutton.classList.toggle('is-active');

        // close by click outside
        if (document.body.classList.contains('mobile-navigation-is-open')) {
          const main = document.querySelector('main');

          main.addEventListener('click', function (e) {
            e.preventDefault();

            document.body.classList.remove('mobile-navigation-is-open');
            document.body.classList.remove('navigation-overlay');
            menubutton.classList.remove('is-active');
          });
        }

        return false;
      });
    }

    // close mobile menu button
    const menuclosebutton = document.querySelector('.l-header__close');

    if (menuclosebutton) {
      menuclosebutton.addEventListener('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        document.body.classList.remove('mobile-navigation-is-open');
        document.body.classList.remove('navigation-overlay');
        document
          .querySelector('.m-headerbuttons__menu')
          .classList.remove('is-active');

        return false;
      });
    }

    // open current item
    if (
      window.breakpoints.current === 'xs' ||
      window.breakpoints.current === 'sm'
    ) {
      const activeMenuItem = document.querySelector(
        '.m-navigation__item.level-0.menu-item-has-children.current-menu-ancestor',
      );

      if (activeMenuItem) {
        activeMenuItem.classList.add('is-toggled');

        if (document.body.classList.contains('mobile-navigation-is-open')) {
          document.body.classList.add('subnavigation-is-open');
        }
      }
    }
  },

  resize: function () {
    if (
      window.breakpoints.current === 'xs' ||
      window.breakpoints.current === 'sm'
    ) {
      document.body.classList.remove('navigation-overlay');

      if (document.body.classList.contains('subnavigation-is-open')) {
        // document.body.classList.remove('subnavigation-is-open')
      }
    } else {
      document.body.classList.remove('subnavigation-is-open');

      if (document.body.classList.contains('mobile-navigation-is-open')) {
        document.body.classList.remove('mobile-navigation-is-open');
        document.body.classList.remove('navigation-overlay');
        document
          .querySelector('.m-headerbuttons__menu')
          .classList.remove('is-active');

        const activeMenuItem = document.querySelector(
          '.m-navigation__item.level-0.menu-item-has-children.is-toggled',
        );

        if (activeMenuItem) {
          activeMenuItem.classList.remove('is-toggled');
        }
      }
    }
  },
};

window.addEventListener(
  'resize',
  function () {
    window.navigation.resize();
  },
  false,
);

window.navigation.init();
