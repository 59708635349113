const openEventDetails = {
  init() {
    this.handleFilterbar();
  },
  open(url) {
    window.location.href = url;
  },
  // The filterBar is not showing on mobile
  handleFilterbar() {
    let filterBar = document.querySelector('.tribe-filter-bar');

    if (filterBar) {
      filterBar.classList.add('tribe-filter-bar--always-open');
    }
  },
};

window.openEventDetails = openEventDetails;
