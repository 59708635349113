window.newsfilter = {
  newsForm: null,
  init: function () {
    newsForm = document.forms['news-filter'];
    if (!newsForm) return;

    const radios = newsForm.getElementsByTagName('input');
    for (let item of radios) {
      item.addEventListener('click', newsfilter.submitForm);
    }
  },
  submitForm: (e) => {
    const searchParams = new URLSearchParams(new URL(location.href).search);
    const pattern = /(page\/)\d\//gm;
    let loc = location.origin + location.pathname;

    // remove paging from url
    if (pattern.test(location.href)) {
      const pos = location.href.search(pattern);

      loc = location.href.substring(0, pos);
    }

    // add searchparams
    const region = searchParams.get('region');

    if (region && e.target.name !== 'region') {
      loc += (loc.indexOf('?') > 0 ? '&' : '?') + 'region=' + region;
    }

    const newsType = searchParams.get('newstype');
    console.log(newsType);
    if (newsType && e.target.name !== 'newstype') {
      loc += (loc.indexOf('?') > 0 ? '&' : '?') + 'newstype=' + newsType;
    }

    loc +=
      (loc.indexOf('?') > 0 ? '&' : '?') + e.target.name + '=' + e.target.value;
    location.href = loc;

    //newsForm.submit();
  },
};

window.newsfilter.init();
