window.expertsForm = {
  init () {
    const expertsForm = document.getElementById('experts-form-widget')
    if (!expertsForm) return;

    expertsForm.addEventListener("submit", window.expertsForm.submitExpertsForm, false);
  },
  submitExpertsForm(e) {
    e.preventDefault();
    let form = new FormData(e.target);
    const formData = Object.fromEntries(form.entries());
    const paramsReduced = Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null && v != ''));
    const action = e.target.getAttribute("action");

    const sanitizedParams={}
    for (const [key, value] of Object.entries(paramsReduced)) {
      sanitizedParams[key] = value
    }

    const apiRequestUrl = new URLSearchParams(sanitizedParams).toString();
    if (apiRequestUrl.length) {
      window.location.href = `${action}?${apiRequestUrl}`;
    } else {
      window.location.href = action;
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  window.expertsForm.init()
}, false)
