import {tns} from 'tiny-slider/src/tiny-slider'

window.gallery = {
  load: function(gallery_base) {
    window.gallery.slider(gallery_base)
  },

  slider: function(gallery_base) {
    const galleryType = gallery_base.closest('.m-gallery__image-container').dataset.galleryType

    var options = {
      "container": gallery_base,
      "autoWidth": (galleryType == 'single' ? false : true),
      "speed": 400,
      // "items": 3,
      "slideBy": 1,
      "lazyload": true,
      "swipeAngle": false,
      "navPosition": 'top',
      "controls": true,
      "loop": true,
      "autoplay": (galleryType == 'single' ? true : false),
      "autoplayTimeout": 4000,
      "responsive": {
        320: {
          items: 1,
          gutter: (galleryType == 'single' ? 0 : 15),
          mouseDrag: true
        },
        768: {
          items: (galleryType == 'single' ? 1 : 2),
          gutter: (galleryType == 'single' ? 0 : 20),
          mouseDrag: false
        },
        1400: {
          items: (galleryType == 'single' ? 1 : 3),
          gutter: (galleryType == 'single' ? 0 : 50),
          mouseDrag: false
        }
      }
    };

    tns(options)
  }
}

window.addEventListener('load', () => {
  const gallery_base = document.querySelectorAll('.m-gallery__images')
  if (gallery_base) {
    for  (const base of gallery_base) {
      window.gallery.load(base)
    }
  }
});
