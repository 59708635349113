import Choices from "choices.js";
import Alpine from "alpinejs";

export default Alpine.data('cselect', function () {
  init: {
    if (!this.$refs.select) return;

    const self = this;
    const currentValue = this.$refs.select.dataset.selected

    const choice = new Choices(this.$refs.select, {
      itemSelectText: '',
      removeItems: true,
      allowHTML: false,
      removeItemButton: true,
    });

    if (currentValue) {
      this.$dispatch('dropdownInit', {
        name: this.$refs.select.name,
        value: currentValue
      })
      choice.setChoiceByValue(currentValue)
    }

    window.addEventListener('reset.window', (e) => {
       choice.setChoiceByValue('')
    })

    this.$refs.select.addEventListener('change', function (event) {
      return event.target.value
    })
  }
});
